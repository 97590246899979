import { useState } from "react";
import "./App.css";

import { CityName, cities } from "./reviews";
import Flex from "./ui/Flex/Flex";

const Reviews = ({
  city,
  clearCity,
}: {
  city: CityName;
  clearCity: () => void;
}) => {
  const [cinema, setCinema] = useState<string | null>();

  return cinema ? (
    <Flex direction="column" rowGap={24}>
      <div>
        <button onClick={() => setCinema(null)}>Tilbake</button>
      </div>
      <div>
        <h2>{cinema}</h2>
        <hr />
      </div>
      <h3>Anmeldelser av {cinema} sine saler</h3>
    </Flex>
  ) : (
    <Flex direction="column" rowGap={24}>
      <div>
        <button onClick={clearCity}>Tilbake</button>
      </div>
      <h2>Velg kino {city}:</h2>
      {Object.entries(cities.find((c) => c.name === city)?.cinemas ?? {}).map(
        ([group, cinemas]) => (
          <Flex direction="column" rowGap={4}>
            <h4>{group}</h4>
            {cinemas.map((cinema) => (
              <div>
                <button onClick={() => setCinema(cinema.name)}>
                  {cinema.name}
                </button>
              </div>
            ))}
          </Flex>
        )
      )}
    </Flex>
  );
};

function App() {
  const [city, setCity] = useState<CityName | null>();

  return (
    <div className="App">
      <main>
        <h1>Kinosaler i Norge</h1>
        {city ? (
          <Reviews city={city} clearCity={() => setCity(null)} />
        ) : (
          <Flex direction="column" rowGap={8}>
            <h2>Velg by:</h2>
            {cities.map(({ name }) => (
              <div key={name}>
                <button onClick={() => setCity(name)}>{name}</button>
              </div>
            ))}
          </Flex>
        )}
      </main>
    </div>
  );
}

export default App;
