type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

type ReviewEntry = {
  value: IntRange<0, 6>;
  label: string;
  description: string;
};

type Review = {
  image: ReviewEntry;
  sound: ReviewEntry;
  seats: ReviewEntry;
  lightPolution: ReviewEntry;
};

type Concept = "IMAX" | "4DX" | "ScreenX" | "Luxe" | "iSense";

type Hall = {
  id: number;
  review: Review;
  technical: {
    dolbyAtmos: boolean;
    concept: Concept;
  };
};

type Cinema = {
  name: string;
  halls: Hall[];
  generalImpressions: ReviewEntry;
};

export type Group = "Odeon" | "Nordisk Film" | "SF" | "Vega";

export type CityName = "Oslo";

type City = {
  name: CityName;
  cinemas: Partial<Record<Group, Cinema[]>>;
};

export const cities: City[] = [
  {
    name: "Oslo",
    cinemas: {
      Odeon: [
        {
          name: "Odeon Oslo",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
      ],
      "Nordisk Film": [
        {
          name: "Colosseum",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
        {
          name: "Gimle",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
        {
          name: "Klingenberg",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
        {
          name: "Ringen",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
        {
          name: "Symra",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
        {
          name: "Vika",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
      ],
      Vega: [
        {
          name: "Vega",
          halls: [],
          generalImpressions: {
            value: 0,
            label: "test",
            description: "test",
          },
        },
      ],
    },
  },
] as const;
